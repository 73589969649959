<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Index",
  created() {
    this.$router.push({
      name: "ProcurerManagementList",
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (
      to.name === "ProcurerManagement" &&
      from.name !== "ProcurerManagement"
    ) {
      this.$router.push({
        name: "ProcurerManagementList",
      });
      return;
    }
    next();
  },
};
</script>
